<template>
  <ProViewProposals :title="title" :tenderers="tenderers" />
</template>

<script>
import ProViewProposals from "@/components/PROSmart/ProViewProposals";

export default {
  components: {
    ProViewProposals,
  },
  name: "ViewPricingProposals.vue",
  data() {
    return {
      title: "Tenderers",
      tenderers: [],
    };
  },
  async mounted() {
    const res = await this.$proSmart.tenderView.getProposals(
      this,
      this.$route.params.id,
      "CommercialOpening"
    );
    this.tenderers = res.reduce(
      (array, { ref, name, commercialFolder: folders }) => {
        return [...array, { ref, name, folders }];
      },
      []
    );
  },
};
</script>
